<template>
<div>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card class="" elevation="" max-width="">
                    <v-card-title class="justify-center">
                        VISUALIZAR USUARIO
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <p>
                                    <router-link exact="" style="text-decoration:none" :to="{name:'inicioPanel'}">Inicio</router-link>
                                    /
                                    <router-link exact="" style="text-decoration:none" :to="{name:'usuariosPanel'}">Usuarios</router-link>
                                    /
                                    <router-link exact="" style="text-decoration:none" :to="{name:'usuariosVisualizarPanel',params: { idusuario: $route.params.idusuario }}">Visualizar</router-link>
                                </p>
                            </v-col>

                            <v-col cols="12">
                                <v-btn text small :to="{name:'usuariosPanel'}"  color="error">
                                    <v-icon left="" small>mdi-arrow-left</v-icon> ATRAS
                                </v-btn>
                            </v-col>

                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" class="text-center" v-show="!mostrarContenido">
                                <v-progress-circular indeterminate color="primary" :size="100" :width="7"></v-progress-circular>
                            </v-col>
                        </v-row>

                        <v-row v-show="mostrarContenido">

                            <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                                <p class="my-0">Correo:</p>
                                <p class="my-0" v-text="usuario.correo"></p>
                            </v-col>

                            <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                                <p class="my-0">Clave:</p>
                                <p class="my-0"></p>
                            </v-col>

                            <v-col cols="12">
                                <p class="my-0">Estado:</p>
                                <v-chip small class="ma-2" color="green" text-color="white" v-if="usuario.estado"> Habilitado </v-chip>
                                <v-chip small class="ma-2" color="red" text-color="white" v-else> Inhabilitado </v-chip>
                            </v-col>
                        </v-row>

                    </v-card-text>

                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
export default {
    data() {
        return {
            usuario: {
                idusuario: '',
                correo: '',
                clave: '',
                estado: ''
            },
            mostrarContenido: false
        }
    },

    methods: {

        getDatos() {
            this.mostrarContenido = false;
            this.axios({
                method: 'GET',
                url: 'api/panel/usuarios/' + this.$route.params.idusuario
            }).then((response) => {

                let status = response.status;
                if (status === 200) {

                    let usuario = response.data.data;
                    this.usuario.correo = usuario.correo;
                    this.usuario.clave = usuario.clave;
                    this.usuario.estado = usuario.estado;

                }

                this.mostrarContenido = true;

            }).catch((error) => {

                this.$toasted.error("Ocurrio un error al buscar este registro", {
                    icon: 'mdi-close'
                });

                this.$router.push({
                    name: 'usuariosPanel'
                });

            }).finally(() => {

            });
        },

    },
    created() {
        console.log(this.$dayjs())
        this.getDatos();
    },
}
</script>
